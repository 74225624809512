import React from 'react'
import './services.scss'
import { Container } from 'react-bootstrap'

const Services = () => {
  return (
    <main className='py-3s'>
      <Container className='container'>
        <div>About us</div>
        <div className='about'>
          <p>Planas Trading Private Limited was incorporated in 2003 and established Planas Stationery Private Limited on the 8th of July 2016</p>
          <p>Planas Stationery has its Head Office located at No. 136 Seke Road Graniteside, Harare.</p>
        </div>
        <div>Our Services</div>

        <div className='services'>
          <p>Planas Stationery is a Zimbabwean retail stationery company, our shops has a large variety of stationery products that are manufactured here in Zimbabwe and outside the country.</p>
          <p>Retail shops across the country support the local manufacturers like Rank and Papyrus to sale their products. As Planas Stationery we also have our own products to showcase in the market which are branded: <br/>i. Planas <br/> ii. Arrow</p>
          <p>Planas Stationery is born to serve the client's needs in the best and effecient way and translate its vision to reality in the market.</p>
        </div>

      </Container>
    </main>
    
  )
}

export default Services