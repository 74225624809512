import React from 'react'
import './navbar.scss'
import {Link, NavLink,useNavigate} from 'react-router-dom'
import planasLogo from '../images/planas_logo-white.png'
import { FaFacebook, FaLinkedin,FaTwitter,FaInstagram } from 'react-icons/fa';

const Navbar = () => {
  return (
    <div className='header'>
      <div className='contacts-header'>
        <div className='social'>
          <div className='twitter'>
                    <a href='https://twitter.com/PlanasInt'><FaTwitter className='FaTwitter' size = { 22 } style = {{ color: '#fff', opacity:1, marginRight: '1rem'}}/></a>
                    </div>
                    <div className='facebook'>
                        <a href='https://www.facebook.com/planastationery'><FaFacebook className='FaFacebook' size = { 22 } style = {{ color: '#fff', opacity:1, marginRight: '1rem'}}/></a>
                    </div>
          <div className='instagram'><a href='https://www.instagram.com/planasstationeryzim/'><FaInstagram className='FaInstagram' size = { 22 } style = {{ color: '#fff', opacity:1, marginRight: '1rem'}}/></a></div>
        </div>
        <div className='contact-us'>
          <div className='contact-card'>Head Office - 0242 777 796</div>
          <div className='contact-card'>Graniteside Shop - 0242 758 825</div>
          <div className='contact-card'>L. Takawira Shop - 0242 750 483</div>
          <div className='contact-card'>Cnr Kwame & L. Takawira Shop - 0242 783 449</div>
          <div className='contact-card'>Cnr N. Mandela & 1st Shop - 0242 783 447</div>
          <div className='contact-card'>Southerton 25 Birmingham Shop - 0242 753 358</div>
          <div className='contact-card'>102 Mbuya Nehanda Shop - 0242 783 450 </div>
          <div className='contact-card'>Marketing 25 Birmingham Shop - 0242 777 666</div>
          <div className='contact-card'>Bulawayo No 69 J. Chinamano Donnington Shop - 0292 461 577</div>
        </div>
      </div>
      <div className='header-main'>
        <div className='logoLink'>
          <img src={planasLogo} alt='Logo'/>
        </div>
        <div className='nav-menu'>
          <ul>
            <li style={{cursor:'Pointer'}}>
            <a href='/'>Planas Stationery</a>
            </li>
            <li>
              {/* <NavLink to='/'>Shop Now</NavLink> */}
              <a href='#'>Products</a>
            </li>
            <li>
              {/* <NavLink to='/'>Shop Now</NavLink> */}
              <a href='https://shop.planas.co.zw'>Shop Online</a>
            </li> 
            <li>
              <NavLink to='/our-services'>Our Business</NavLink>
              {/* <a href='https://planas.co.zw/our-services'>Our Services</a> */}
            </li> 
            <li>
              <NavLink to='/our-services'>About us</NavLink>
              {/* <a href='https://planas.co.zw/our-services'>Our Services</a> */}
            </li>
          </ul>
        </div>
      </div>
      
    </div>
  )
}

export default Navbar