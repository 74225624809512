import React from 'react'
import './home.scss'
import { Container } from 'react-bootstrap'
import bond from '../images/page_web_poster.png'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import RedeemIcon from '@mui/icons-material/Redeem';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SvgUndraw from '../images/undraw_shopping_bags_8l6g.svg'
import Envelopes from '../images/brown_envelopes-01.png'
import Puncher from '../images/deli_puncher_Blue_sq.jpg'
import Stapler from '../images/1579265047--DL-E0484_1.png'
import SlideImage from './SlideImage';

const Home = ({underMaintenance}) => {

    

  return (
    <main className='py-3 pt-0'>
        <Container className='container'>
            <div className='home-cover'>
                
                <div className='description'>

                    <div className='web-intro'>
                        <div className='intro'>
                            <img src={SvgUndraw} alt="school"/>
                        </div>
                        
                        <div className='shop-now' onClick={()=>underMaintenance()}>
                           
                            <h6 >Shop Now <ShoppingCartIcon/></h6>
                            
                        </div>

                        <div className='get-quote' onClick={()=>underMaintenance()}>
                            <h6>Get A Quotation Now <RequestQuoteIcon/></h6>
                        </div>

                        <div className='gift-voucher' >
                            <input type='text' disabled="true" placeholder='Enter Your Gift Voucher / Coupon Code' />
                            <button className='btn-redeem-coupon' onClick={()=>underMaintenance()}> Redeem <RedeemIcon/></button>
                        </div>
                    </div>
                </div>

                <div className='image-video'>
                    <SlideImage/>
                </div>

            </div>

            <div className="category-cards">
                <div className="adverts">
                    advert
                </div>

                <div className="first-category">
                    
                    <div className='fi-heading'>
                        <h4>Business Stationery</h4>
                    </div>
                    <div className='fi-description'>
                        <span></span>
                    </div>
                    <div className='fi-cards'>
                        <div className='cards'>
                            <div className='first'></div>
                            <div className='first'></div>
                            <div className='first'></div>
                            <div className='first'></div>
                            <div className='first'></div>
                            <div className='first'></div>
                        </div>
                    </div>
                </div>

                <div className="second-category">
                    
                    <div className='se-heading'>
                        <h4>Office Supplies</h4>
                    </div>
                    <div className='se-description'>
                        <span></span>
                    </div>
                    <div className='se-cards'>
                        <div className='cards'>
                            <div className='first'>
                                <img src={Envelopes} alt="envelopes"/>
                            </div>
                            <div className='first'>
                                <img src={Puncher} alt="puncher"/>
                            </div>
                            <div className='first'>
                                <img src={Stapler}/>
                            </div>
                            <div className='first'></div>
                            <div className='first'></div>
                            <div className='first'></div>
                        </div>
                    </div>
                </div>

                <div className="third-category">
                    <div className='th-heading'>
                        <h4>Drawing & Writing Instruments</h4>
                    </div>
                    <div className='th-description'>
                        <span></span>
                    </div>
                    <div className='th-cards'>

                    </div>
                </div>

                <div className="fourth-category">
                    
                    <div className='fo-heading'>
                        <h4>Ink & Toner</h4>
                    </div>
                    <div className='fo-description'>
                        <span></span>
                    </div>
                    <div className='fo-cards'>

                    </div>
                </div>

                <div className="fifth-category">
                    
                    <div className='fif-heading'>
                        <h4>Filing & Storage</h4>
                    </div>
                    <div className='fif-description'>
                        <span></span>
                    </div>
                    <div className='fif-cards'>

                    </div>
                </div>

                <div className="sixth-category">
                    
                    <div className='si-heading'>
                        <h4>Presentation Supplies</h4>
                    </div>
                    <div className='si-description'>
                        <span></span>
                    </div>
                    <div className='si-cards'>

                    </div>
                </div>

                <div className="seventh-category">
                    <div className='sev-heading'>
                        <h4>School Supplies</h4>
                    </div>
                    <div className='sev-description'>
                        <span></span>
                    </div>
                    <div className='sev-cards'>

                    </div>
                </div>

                <div className="eighth-category">
                    <div className='ei-heading'>
                        <h4>Accessories, Toys & Games</h4>
                    </div>
                    <div className='ei-description'>
                        <span></span>
                    </div>
                    <div className='ei-cards'>

                    </div>
                </div>
            </div>

        </Container>

    </main>
  )
}

export default Home