import { fontWeight } from '@mui/system';
import React,{useEffect} from 'react'
import { Container } from 'react-bootstrap'
import './terms.scss';

const TermsNConditons = () => {
  

  

  useEffect(() => {
    const tab_list = document.querySelectorAll(".terms-bar ul li");
    const tab_items = document.querySelectorAll(".tab_item");

    tab_list.forEach((list) => {
      list.addEventListener("click", ()=>{
        const tab_data = list.getAttribute("data-tc");
        //console.log(tab_data)
  
        tab_list.forEach((list)=>{ 
          list.classList.remove("active");
        });
        list.classList.add("active");
  
        tab_items.forEach((item) => {
          const tab_class = item.getAttribute("class").split(" ");
          //console.log(tab_class)
          if(tab_class.includes(tab_data)){
            item.style.display = "block";
          }
          else{
            item.style.display = "none";
          }
        })
      })
    })
  },[]);

  return (
    <main className='main-terms py-3'>
      <Container className='container-terms'>
        <div className='terms-bar'>
          <ul>
            <li data-tc="tab_item_1" className='active'>Introduction</li>
            <li data-tc="tab_item_2">Important Notice</li>
            <li data-tc="tab_item_3">Breakages and Returns</li>
            <li data-tc="tab_item_4">Registration and use of the website</li>
            <li data-tc="tab_item_5">Conclusion of sales and availability of stock</li>
            <li data-tc="tab_item_6">Payment</li>
            <li data-tc="tab_item_7">Delivery of Goods</li>
            <li data-tc="tab_item_8">Errors</li>
            <li data-tc="tab_item_9">Gift Vouchers And Coupons</li>
            <li data-tc="tab_item_10">Daily Deals And Other Discounted Goods</li>
            <li data-tc="tab_item_11">Third Party Sellers</li>
            <li data-tc="tab_item_12">Private Policy</li>
            <li data-tc="tab_item_13">Changes to these Terms and Conditions</li>
            <li data-tc="tab_item_13">Electronic Communications</li>
            <li data-tc="tab_item_13">Ownership and copyright</li>
          </ul>
          
        </div>
        <div className='terms-details'>

          <div className='heading'>
            <h4 style={{color:'#007E46'}}><strong>Terms and Conditions (T&Cs)</strong></h4>
            <h7 style={{color:'#007E46'}}><i>Effective last update: 29 August 2022</i></h7>
          </div>
          
          <div className='details'>
            <div className='tab_item tab_item_1'>
            <h7 style={{color:'#007E46',fontWeight:'bold'}}>Introduction</h7>
            <p>1.	This website can be accessed at <strong>https://planas.co.zw/</strong> or <strong>https://shop.planas.co.zw/</strong> , related mobile-sites and software applications (the “Website”) and is owned and operated by Planas Stationery (Pvt) Limited (“Planas Stationery”, “we”, “us” and “our”).</p>
            <p>2. These Website Terms and Conditions (“Terms and Conditions”) govern the ordering, sale and delivery of Goods, and the use of the Website.</p>
            <p>3.	These Terms and Conditions are binding and enforceable against every person that accesses or uses this Website (“you”, “your” or “user”), including without limitation each user who registers as contemplated below (“registered user”). By using the Website and by clicking on the “Register Now” button on the Website, as may be applicable, you acknowledge that you have read and agree to be bound by these Terms and Conditions.</p>
            <p>4.	The Website enables you to shop online for an extensive range of goods including sport, home and books, book and pen products, electronics, office and beauty products, movies and TV, gaming, books, toys, phones, and more (“Goods”).</p>
            </div>
            <div className='tab_item tab_item_2'>
            <h7 style={{color:'#007E46',fontWeight:'bold'}}>Important Notice</h7>
            </div>
            <div className='tab_item tab_item_3'>
            <h7 style={{color:'#007E46',fontWeight:'bold'}}>Breakages and Returns</h7>
            <p>1.	Please refer to our <a href='/breakages-and-returns-policy'>Breakages and Returns Policy</a> for more information about returning products (and related refunds, replacements or repairs).  The Breakages and Returns Policy is incorporated by reference (which means that it forms part of these Terms and Conditions).</p>
            </div>
            <div className='tab_item tab_item_4'>
            <h7 style={{color:'#007E46',fontWeight:'bold'}}>Registration and use of the website</h7>
            <p>1. Only registered users may order Goods on the Website.</p>
            <p>2. To register as a user, you must provide a unique username,email and password and provide certain information and personal details to Planas Stationery Online Shop. You will need to use your email and password to access the Website in order to purchase Goods.</p>
            <p>3.	You agree and warrant that your username, email and password shall: <br/>3.1.	be used for personal use only; and <br/>3.2. not be disclosed by you to any third party.</p>
            <p>4.	For security purposes you agree to enter the correct email and password whenever ordering Goods, failing which you will be denied access.</p>
            <p>5.	You agree that, once the correct username, email and password relating to your account have been entered, irrespective of whether the use of the username, email and password is unauthorised or fraudulent, you will be liable for payment of such order, save where the order is cancelled by you in accordance with these Terms and Conditions.</p>
            <p>6.	You agree to notify Planas Stationery Online Shop immediately upon becoming aware of or reasonably suspecting any unauthorised access to or use of your username,email and password and to take steps to mitigate any resultant loss or harm.</p>
            <p>7.	By using the Website you warrant that you are 18 (eighteen) years of age or older and of full legal capacity. If you are under the age of 18 (eighteen) or if you are not legally permitted to enter into a binding agreement, then you may use the Website only with the involvement and supervision of your parent or legal guardian. If your parent or legal guardian supervises you and gives his/her consent, then such person agrees to be bound to these Terms and Conditions and to be liable and responsible for you and all your obligations under these Terms and Conditions.</p>
            <p>8.	You agree that you will not in any way use any device, software or other instrument to interfere or attempt to interfere with the proper working of the Website. In addition, you agree that you will not in any way use any robot, spider, other automatic device, or manual process to monitor, copy, distribute or modify the Website or the information contained herein, without the prior written consent from an authorised Planas Stationery representative (such consent is deemed given for standard search engine technology employed by Internet search websites to direct Internet users to this Website).</p>
            <p>9. You may not use the Website to distribute material which is defamatory, offensive, contains or amounts to hate speech or is otherwise unlawful.</p>
            <p>10.	You may not in any way display, publish, copy, print, post or otherwise use the Website and/or the information contained therein without the express prior written consent of an authorised Planas Stationery Online Shop representative.</p>
            </div>
            <div className='tab_item tab_item_5'>
              <h7 style={{color:'#007E46',fontWeight:'bold'}}>Conclusion of sales and availability of stock</h7>
              <p>1.	Registered users may place orders for Goods, which Planas Stationery Online Shop, Marketing Department or Retail Shop may accept or reject. Whether or not Planas Stationery Online Shop, Marketing Department or Retail Shop accepts an order depends on the availability of Goods, correctness of the information relating to the Goods (including without limitation the price) and receipt of payment or payment authorisation by Planas Stationery Online Shop for the Goods.</p>
              <p>2.	<strong>NOTE: Planas Stationery Online Shop, Marketing or Retail Shop will indicate the acceptance of your order by delivering the Goods to you or allowing you to collect them, and only at that point will an agreement of sale between you and Planas Stationery Online Shop, Marketing or Retail Shop come into effect (the “Sale”). This is regardless of any communication from Planas Stationery Online Shop stating that your order or payment has been confirmed. Planas Stationery Online Shop will indicate the rejection of your order (by Planas Stationery Online Shop itself) by cancelling it and, as soon as possible thereafter, refunding you for any amount already paid.</strong></p>
              <p>3.	Prior to delivery or your collection of the Goods, you may cancel an order at any time provided you do so before receiving a dispatch or delivery notice. After delivery or your collection of the Goods, you may return the Goods only in accordance with the Breakages and Returns Policy.</p>
              <p>4.	Placing Goods in a pending carts or shopping basket/cart without completing the purchase cycle does not constitute an order for such Goods, and as such, Goods may be removed from the shopping basket/cart if stock is no longer available or the price thereof might change without notice to you. You cannot hold Planas Stationery Online Shop liable if such Goods are not available or at the particular price when you complete or attempt to complete the purchase cycle at a later stage.</p>
              <p>5.	You acknowledge that stock of all Goods on offer is limited and that pricing may change at any time without notice to you. In the case of Goods for sale by Planas Stationery Online Shop, Online Shop will take all reasonable efforts to monitor stock levels and ensure that when stock is no longer available, that offers thereof are discontinued on the Website. However, we cannot guarantee the availability of stock. When Goods are no longer available after you have placed an order, Online Shop will notify you and you will be entitled to a refund of any amount already paid by you for such Goods. </p>
              <p>6.	Certain Goods may not be purchased for re-sale. Should we suspect that any such Goods are being purchased for sale, we are entitled to cancel your order immediately on notice to you.</p>
              <p>7.	Please see details relating to Pre-orders in our <a href='/help-centre'>Help Centre</a>: Pre-Order, which are incorporated by reference.</p>
            </div>
          </div>
        </div>
      </Container>
    </main>
  )
}

export default TermsNConditons