import React from 'react'
import './footer.scss'
import { Container } from 'react-bootstrap'
import CopyrightIcon from '@mui/icons-material/Copyright';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { FaFacebook, FaLinkedin,FaTwitter,FaInstagram } from 'react-icons/fa';
import Logo from '../images/planasLOGO.png';
import ScrollToTop from "react-scroll-to-top";

const Footer = () => {
  return (

            <div className='footer'>
                <ScrollToTop smooth top="400" color='#007E46'/>
                <div className='logo'>
                    <img src={Logo}/>
                </div>
                <div className='company'>
                    {/* <div>
                    <a href='#'>
                        <p style={{fontSize:'12px',cursor:'pointer'}}>About us</p>
                    </a></div> */}
                    <div><a href='#'>
                        <p style={{fontSize:'12px',cursor:'pointer'}}>Contact us</p>
                    </a></div>
                    <div><a href='#'>
                        <p style={{fontSize:'12px',cursor:'pointer'}}>Help Centre</p>
                    </a></div>
                    <div><a href='/our-services'>
                        <p style={{fontSize:'12px',cursor:'pointer'}}>Our Services</p>
                    </a></div>
                    <div><a href='#'>
                        <p style={{fontSize:'12px',cursor:'pointer'}}>Private Policy</p>
                    </a></div>
                    <div><a href='#'>
                        <p style={{fontSize:'12px',cursor:'pointer'}}>Breakages and Returns Policy</p>
                    </a></div>
                    <div><a href='#'>
                        <p style={{fontSize:'12px',cursor:'pointer'}}>Gift Vouchers And Coupons T&Cs</p>
                    </a></div>
                    <div><a href='#'>
                        <p style={{fontSize:'12px',cursor:'pointer'}}>Terms And Conditions (T&Cs)</p>
                    </a></div>
                </div>
                <div className='social-media'>
                    <div className='twitter'>
                    <a href='https://twitter.com/PlanasInt'><FaTwitter className='FaTwitter' size = { 30 } style = {{ color: '#000', opacity:1, marginRight: '1rem'}}/></a>
                    </div>
                    <div className='facebook'>
                        <a href='https://www.facebook.com/planastationery'><FaFacebook className='FaFacebook' size = { 30 } style = {{ color: '#000', opacity:1, marginRight: '1rem'}}/></a>
                    </div>
                    <div className='instagram'><a href='https://www.instagram.com/planasstationeryzim/'><FaInstagram className='FaInstagram' size = { 30 } style = {{ color: '#000', opacity:1, marginRight: '1rem'}}/></a></div>
                </div>

                <div className='retail-shops'>
                    <div>
                    <a href='#'>
                        <p style={{fontSize:'12px',cursor:'pointer'}}>Online Shop</p>
                    </a></div>
                    <div>
                    <a href='#'>
                        <p style={{fontSize:'12px',cursor:'pointer'}}>Retail Shop Byo (Donnington)</p>
                    </a></div>
                    <div>
                    <a href='#'>
                        <p style={{fontSize:'12px',cursor:'pointer'}}>Retail Shop 136 (Graniteside)</p>
                    </a></div>
                    <div><a href='#'>
                        <p style={{fontSize:'12px',cursor:'pointer'}}>Retail Shop 1 (CBD Hre)</p>
                    </a></div>
                    <div><a href='#'>
                        <p style={{fontSize:'12px',cursor:'pointer'}}>Retail Shop 2 (CBD Hre)</p>
                    </a></div>
                    <div><a href='#'>
                        <p style={{fontSize:'12px',cursor:'pointer'}}>Retail Shop 4 (CBD Hre)</p>
                    </a></div>
                    <div><a href='#'>
                        <p style={{fontSize:'12px',cursor:'pointer'}}>Retail Shop 5 (CBD Hre)</p>
                    </a></div>
                    <div><a href='#'>
                        <p style={{fontSize:'12px',cursor:'pointer'}}>Retail Shop 25 (Southerton)</p>
                    </a></div>
                </div>
                <div className='all-rights'>
                    <CopyrightIcon/>Planas Stationery 2022. All Rights Reserved.
                </div>
                <div className='developer'>
                    <a href='https://www.linkedin.com/in/tineyi-chinhengo-9710bb141'>
                        <p style={{fontSize:'12px',cursor:'pointer'}}>System By Tineyi Chinhengo</p>
                    </a>
                </div>
            </div>
  )
}

export default Footer