import React from 'react'
import './pageNotFoundStyles.scss'
import {Link} from 'react-router-dom'
import { Container } from 'react-bootstrap'
import errorimage from '../images/404-not-found-error-sign.jpg'

const PageNotFound = () => {
  return (
    <main className='py-3'>
    <Container className='mt-5'>
        <div className='notFound'>
            <img src={errorimage} height='200' width='200'/>
            <div className='text'>
              <h2>Oops! 404 Error</h2>
              <h4>Page not found</h4>
              <Link to='/'>Back to homepage...</Link>
            </div>
            
        </div>
    </Container></main>
    
  )
}

export default PageNotFound

