import React,{useState,useEffect} from 'react'
import './SlideImageStyles.scss'
import {AiOutlineArrowLeft,AiOutlineArrowRight} from 'react-icons/ai'
import {sliderData} from './slider-data'

const SlideImage = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const slideLength = sliderData.length
  const [showLink, setShowLink] = useState(true);

  const autoScroll = true;
  let slideInterval;
  let intervalTime = 5000;

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength -1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slideLength -1 : currentSlide - 1);
  };

  function auto(){
    slideInterval = setInterval(nextSlide, intervalTime)
  }

  useEffect(() => {
    setCurrentSlide(0)

    if(window.matchMedia("(max-width: 100px)").matches){
      setShowLink(false);
    }else{
      setShowLink(true);
    }
  },[])

  useEffect(() => {
    if(autoScroll){
      auto()
    }
    return () => clearInterval(slideInterval)
  },[currentSlide])

  return (
  <>

    {showLink?
        <div className='slider'>
          {/* <AiOutlineArrowLeft className='arrow prev' onClick={prevSlide}/>
          <AiOutlineArrowRight className='arrow next' onClick={nextSlide}/> */}

          {sliderData.map((slide, index) => {
            return (
              <div className={index === currentSlide ? 
                'slide current' : 'slide'} key={index}>

                  {index === currentSlide && (
                    <>
                      <img src={slide.image} alt='slide' />
                    </>
                  )}
                </div>
            )
          })}
      </div>
    :''}

  </>
  )
}

export default SlideImage