export const sliderData = [
    { 
        image:"/images/IMG_20221205_130400.jpg",
        heading:"",
        desc:"",
    },
    { 
        image:"/images/3-2-1-2.jpg",
        heading:"",
        desc:"",
    },

    { 
        image:"/images/1579265047--DL-E0484_1.png",
        heading:"",
        desc:"",
    },
    { 
        image:"/images/pen_organiser_edited.jpg",
        heading:"",
        desc:"",
    },
    // {
    //     image:"/images/image1.jpg",
    //     heading:"Slide One",
    //     desc:"This is description Lorem ipsum dolor sit amet, consectetur adipiscing elit.Curabitur in lacus nec ligula sollicitudin pellentesque. Integer sed rutrum quam, sit amet semper metus. Maecenas vel ullamcorper elit. Suspendisse vel quam nunc"
    // },
    // {
    //     image:"/images/image2.jpg",
    //     heading:"Slide Two",
    //     desc:"This is description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur in lacus nec ligula sollicitudin pellentesque. Integer sed rutrum quam, sit amet semper metus. Maecenas vel ullamcorper elit. Suspendisse vel quam nunc"
    // },
    // { 
    //     image:"/images/image3.jpg",
    //     heading:"Slide Three",
    //     desc:"This is description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur in lacus nec ligula sollicitudin pellentesque. Integer sed rutrum quam, sit amet semper metus. Maecenas vel ullamcorper elit. Suspendisse vel quam nunc"
    // },

]