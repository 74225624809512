import React from 'react'
import { Container } from 'react-bootstrap'

const PrivatePolicy = () => {
  return (
    <main className='py-3s'>
      <Container className='cotainer'>
      <div>PrivatePolicy</div>
      </Container>
    </main>
    
  )
}

export default PrivatePolicy