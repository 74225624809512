import React, {useState, useEffect} from 'react'
import {Routes, Route} from 'react-router-dom'
import Footer from './components/Footer';
import Aboutus from './components/Footer/Aboutus';
import Contactus from './components/Footer/Contactus';
import PrivatePolicy from './components/Footer/PrivatePolicy';
import ReturnsPolicy from './components/Footer/ReturnsPolicy';
import Services from './components/Footer/Services';
import TermsNConditons from './components/Footer/TermsNConditons';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Swal from 'sweetalert2'
import PageNotFound from './components/PageNotFound';

function App() {

  const underMaintenance=()=>{
    Swal.fire({
      title: 'Unavailable',
      text: 'We don\'t have any voucher/coupon distributed yet.',
      icon: 'info',
      confirmButtonText: 'Okay'
    })
  }

  return (
    <React.Fragment>
      
      <Navbar/>

      <Routes>
        <Route path='/' element={<Home underMaintenance={underMaintenance}/>}/>
        <Route path='/private-policy' element={<PrivatePolicy/>}/>
        <Route path='/terms-and-conditions' element={<TermsNConditons/>}/>
        <Route path='/contact-us' element={<Contactus/>}/>
        <Route path='/about-us' element={<Aboutus/>}/>
        <Route path='/our-services' element={<Services/>}/>
        <Route path='/breakages-and-returns-policy' element={<ReturnsPolicy/>}/>
        <Route path='*' element={<PageNotFound/>}/>
      </Routes>

      <Footer underMaintenance={underMaintenance}/>

    </React.Fragment>
  );
}

export default App;
